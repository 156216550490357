<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Contenidos</h4>
            <div class="small text-muted">Administración de todos los contenidos para interfaces web</div>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="9">
          <b-row>
            <b-col lg="4"> 
              <Widget :configWidget="configWidget" reference="cant_unconfiguredBlocks" :hasAccess="configWidget.elements.cantUnconfiguredBlocks" />
            </b-col>
            <b-col lg="4"> 
              <Widget :configWidget="configWidget" reference="list_unconfiguredSite" :hasAccess="configWidget.elements.listUnconfiguredSite" />
            </b-col>
          </b-row>
        </b-col>  
        <b-col lg="3" md="12" sm="12">

          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openSettings()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Configuraciones</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Configuración general del sitio web
              </p>
            </b-list-group-item>
          </b-list-group> 

          <b-list-group v-if="parameters.havePage">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openPages()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Páginas</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administrar las páginas que se muestran en el sitio web
              </p>
            </b-list-group-item>
          </b-list-group> 

          <b-list-group v-if="false && parameters.havePopups">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openPopups()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Popups</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administrar popups para mostrar en sitio web
              </p>
            </b-list-group-item>
          </b-list-group>   
          
          <b-list-group v-if="parameters.haveDownloads">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openDownloads()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Descargas</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administrar archivos de descargas para sitio web
              </p>
            </b-list-group-item>
          </b-list-group> 

          <!-- DESCONTINUADOS -->
          <b-list-group v-if="parameters.haveSliders">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openSliders()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Sliders</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administrar sliders para mostrar sitio web
              </p>
            </b-list-group-item>
          </b-list-group>                        

          <b-list-group v-if="parameters.havePaymentMethods">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openPaymentMethods()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Metodos de Pago</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administrar métodos de pagos para visualizar en sitio web
              </p>
            </b-list-group-item>
          </b-list-group> 
          <!-- FIN DESCONTINUADOS -->

          <b-list-group v-if="false">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openStoreDeliveries()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Entregas</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administrar todas las formas de entregas de un pedidos
              </p>
            </b-list-group-item>
          </b-list-group> 

          <b-list-group v-if="false">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openStoreDiscounts()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Cupones de Descuentos</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administrar todos los cupones de descuentos disponibles para usar en la tienda
              </p>
            </b-list-group-item>
          </b-list-group> 

          <b-list-group class="mt-3">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openTemplate()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Selector de Plantillas</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Selector de plantillas dinámico para sitio web
              </p>
            </b-list-group-item>
          </b-list-group> 

          <b-list-group v-if="parameters.havePortabilityTemplate">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openPortabilityTemplate()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Portabilidad</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Carga y descarga de plantillas
              </p>
            </b-list-group-item>
          </b-list-group>           

        </b-col>        
      </b-row>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Widget from '@/components/inc/widget/widget'
  import Param from '@/config/parameters'
  
  export default {
    components: {
      Widget
    },
    data: () => {
      return {
        access: {
          module_id: Modules.CONTENIDOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'dashboard',
          elements: {}
        },
        configWidget: {
          module_id: Modules.CONTENIDOS,
          profile_id: Profiles.PERSONAL,
          elements: {             
            cantUnconfiguredBlocks: true,
            infoUnconfiguredSite: true,
          }
        },
        parameters: {
          haveSliders: Helper.hasParametersAccess(Param.P11),
          havePopups: Helper.hasParametersAccess(Param.P12),
          haveDownloads: Helper.hasParametersAccess(Param.P13),
          havePaymentMethods: Helper.hasParametersAccess(Param.P14),
          havePage: Helper.hasParametersAccess(Param.P15),
          havePortabilityTemplate: Helper.hasParametersAccess(Param.P45)
        },           
        primaryColor: '',
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Configurar permisos de los widgets */      
      this.configWidget.elements.cantUnconfiguredBlocks = Helper.hasAccessWidget(this.configWidget, 'cant_unconfiguredBlocks')
      this.configWidget.elements.listUnconfiguredSite = Helper.hasAccessWidget(this.configWidget, 'list_unconfiguredSite')
      /* Fin configuracion */
    },
    mounted() {
      this.setup()
    }, 
    methods: {
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      }, 

      openSettings() {
        this.$router.push({ name: 'ContentsSttaffSettings' })
      },

      openPages() {
        this.$router.push({ name: 'ContentsSttaffPages' })
      },

      openSliders() {
        this.$router.push({ name: 'ContentsSttaffSliders' })
      },

      openPopups() {
        this.$router.push({ name: 'ContentsSttaffPopups' })
      },

      openDownloads() {
        this.$router.push({ name: 'ContentsSttaffDownloads' })
      },

      openPaymentMethods() {
        this.$router.push({ name: 'ContentsSttaffPaymentMethods' })
      },

      openStoreDeliveries() {
        this.$router.push({ name: 'ContentsSttaffStoreDeliveries' })        
      },

      openStoreDiscounts() {
        this.$router.push({ name: 'ContentsSttaffStoreDiscounts' })        
      },

      openPortabilityTemplate() {
        this.$router.push({ name: 'ContentsStaffPortabilityTemplate' })        
      },
      
      openTemplate() {
        this.$router.push({ name: 'ContentsStaffTemplate' })        
      }

    }    
   
  }
</script>

<style>

</style>
